import styled, { css } from "styled-components";
import intl from "../../services/intl";
import classNames from "classnames";

import GridRow from "../grid/GridRow";
import GridColumn from "../grid/GridColumn";
import ScrollableOverflow from "../ScrollableOverflow";

import { ValueProp } from "@ritual/essentials-for-react";

import {
  Product,
  ValuePropType,
  NutrientOrIngredient,
  Notation,
  Allergen,
  Attribution,
} from "../../utils/productTypes";

type Certification = {
  label: string;
  srcUrl: string;
  description: string;
};

export type PanelProps = {
  item: Product;
};

type StyledCSSProps = {
  nutrientCount?: number;
  ingredientCount?: number;
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2, 32px);
`;

const ScrollableOverflowExtend = styled(ScrollableOverflow)`
  .scrollable-overflow-draggable-content {
    gap: var(--spacing-1_5, 24px);
  }
`;

const CertificationsRow = styled.div`
  display: flex;
  gap: var(--spacing-1_5, 24px);
  padding-top: var(--spacing-1_5, 24px);

  img {
    width: 80px;
    height: 80px;
  }

  @media(min-width: 725px) {
    padding-top: var(--spacing-2, 32px);
  }
`;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2, 32px);
  padding-bottom: var(--spacing-3, 48px);
`

const ValuePropContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1_5, 24px);
`;

const ValuePropWrapper = styled.div`
  div.value-prop {
    gap: var(--spacing-1, 16px);

    span:first-of-type {
      display: inline-flex;
      flex-direction: column;
    }
  }
`;

const FactsPanelWrapper = styled.div<StyledCSSProps>`
  border: 1px solid var(--indigo-blue);
  padding: var(--spacing-0_75, 12px) var(--spacing-0_75, 12px) 0;

  .r-grid-row {
    grid-row-gap: var(--spacing-1, 16px);
  }

  ${(p: any) =>
    p.nutrientCount &&
    css`
      div:nth-child(${p.nutrientCount} of div.nutrients) {
        border-bottom: 2px solid var(--indigo-blue);
      }
    `}

  ${(p: any) =>
    p.ingredientCount &&
    css`
      div:nth-child(${p.ingredientCount} of div.ingredients) {
        border-bottom: 2px solid var(--indigo-blue);
      }
    `}
`;
const FactsPanelHeader = styled.div`
  border-bottom: 2px solid var(--indigo-blue);
  padding-bottom: var(--spacing-1, 16px);

  .serving-size span:last-of-type,
  .container-servings span:last-of-type {
    font-family: var(--font-circular);
    font-weight: 450;
  }
`;
const FactsPanelTitle = styled.p`
  font-weight: 450;
`;

const GridCell = styled.div`
  &.dosage {
    text-align: end;
  }

  .cell-label {
    &.nutrient {
      &:not(.sub-ingredient) {
        display: inline-flex;
        flex-direction: column;
      }

      .cell-label-copy {
        font-weight: 500;
      }

      .cell-label-copy i {
        font-family: var(--font-dutch);
      }
    }

    &.ingredient {
      display: inline-flex;
      flex-direction: column;

      .cell-label-copy {
        font-weight: 500;
      }

      .cell-label-copy i {
        font-family: var(--font-dutch);
      }

      span.typography-caption {
        display: inline-block;
      }
    }

    &.sub-ingredient {
      .cell-label-copy {
        font-weight: 450;

        span {
          display: inline-flex;
          flex-flow: column;
          align-items: baseline;
        }
      }

      div:not(div.cell-label-copy *) {
        display: inline-flex;
        flex-flow: column;
      }
    }

    .cell-label-copy:not(.block-display) {
      display: inline-flex;
      flex-direction: column;
    }

    span {
      font-weight: 450 !important;

      &.typography-caption {
        color: var(--indigo-blue-60);
      }
    }

    i {
      font-family: var(--font-dutch);
    }

    &.sub-ingredient {
      margin-left: var(--spacing-1);
    }

    @media (max-width: 768px) {
      .cell-label-copy:not(.block-display) {
        display: inline-flex;
        flex-direction: column;
      }
    }
  }
`;

const FactsPanelRow = styled.div.attrs({
  className: "typography-body3",
})`
  font-family: var(--font-circular);
  font-weight: 500;
  padding: var(--spacing-0_75, 12px) 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--indigo-blue-20);

  .nutrient-serving {
    display: flex;
    align-items: center;
    gap: var(--spacing-1, 16px);
    text-align: right;
    justify-content: end;
  }
`;

const FactsPanelRowLabels = styled(FactsPanelRow).attrs({
  className: "typography-label3 labels",
})`
  display: block;
  text-align: right;
  font-weight: 450;
  text-wrap: nowrap;
  border-bottom: 2px solid var(--indigo-blue);

  &.header-row {
    border-bottom: 2px solid var(--indigo-blue);
  }
`;

const FactsPanelNutrient = styled(FactsPanelRow).attrs({
  className: "nutrients",
})``;

const FactsPanelIngredient = styled(FactsPanelRow).attrs({
  className: "ingredients",
})``;

const FactsPanelFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1, 16px);
`;

const FactsPanelAttributions = styled.div`
  display: flex;
  flex-direction: column;
`;

const OtherIngredients = styled.div.attrs({
  className: "typography-body3 font-circular",
})`
  font-weight: 450;
`;
const FactsPanelBodyLabel = styled.span.attrs({
  className: "typography-label3",
})``;

const FactsPanelBodyContent = styled.span.attrs({
  className: "typography-body3 font-circular",
})`
  font-weight: 450;
`;

const FactsPanelCaption = styled.span.attrs({
  className: "typography-caption",
})`
  font-weight: 450;

  .notation-references {
    padding: var(--spacing-0_75, 12px) 0;
  }
`;


const SupplementFactsPanel = ({ item }: PanelProps) => {
  if (!item) return null;

  const { valueProps, supplementFacts } = item;

  const {
    labels,
    nutrients,
    ingredients,
    servingSize,
    servingsPerContainer,
    notations,
    notationReferences,
    otherIngredients,
    allergens,
    attributions,
    certifications,
  } = supplementFacts;

  const Certifications: Certification[] = [
    {
      label: "usp",
      srcUrl:
        "https://images.ctfassets.net/uuc5ok478nyh/4oun3lVF7fcWAKQnS9ZC5d/1d5aeeeef1749d49e17411e85ed84077/COA-Certification-USP.png",
      description: "USP Certification",
    },
    {
      label: "non-gmo",
      srcUrl:
        "https://images.ctfassets.net/uuc5ok478nyh/22J2y1K1bdIvgNL3TYoVno/99233f7b860ab287fe79f667a4001f18/COA-Certification-Non-GMO.png",
      description: "Non-GMO Certification",
    },
    {
      label: "sport",
      srcUrl:
        "https://images.ctfassets.net/uuc5ok478nyh/3TJaWs78sW4isOEsZ6WvVt/01512d2db9b09e485fc6959998958706/Informed_Sport.png",
      description: "Informed Sport Certification",
    },
    {
      label: "protein",
      srcUrl:
        "https://images.ctfassets.net/uuc5ok478nyh/2FWGyzh09fmlCazxexs5TJ/10bd6a6993205fd8a33e614b7f9b328d/Informed_Protein.png",
      description: "Informed Protein Certification",
    },
    {
      label: "carbon-free",
      srcUrl:
        "https://images.ctfassets.net/uuc5ok478nyh/6gQpawx14e8UuSVVibZW3N/93cc64bfdfae04cca20b497d5930bfec/Carbonfree.png",
      description: "Carbon Free Certification",
    },
    {
      label: "clean-label",
      srcUrl:
        "https://images.ctfassets.net/uuc5ok478nyh/7DDs0rRUHdeXQFW3m1DXCa/4193defefba7acc5deca4c0ca4c54716/Clean-Label.png",
      description: "Clean Label Certification",
    },
    {
      label: "clean-label-purity",
      srcUrl:
        "https://images.ctfassets.net/uuc5ok478nyh/15JjSXMN5BDxfwkQ9udEP1/faa5efa4c3ff18a12af4d2856ac8d5ca/Clean_Label_Purity.png",
      description: "Clean Label Purity award",
    },
  ];
  const productCertifications = Certifications.filter((cert: Certification) =>
    certifications?.includes(cert.label),
  );

  const isHyacera = item.sku === "SKNCVA0";
  const showAllergenSection = allergens && allergens.length > 0;

  return (
    <ContentContainer>
      {productCertifications && (
        <CertificationsRow>
          <ScrollableOverflowExtend centered={false} bleedRight={true} bleedLeft={true}>
            {productCertifications.map((object) => (
              <img
                src={object.srcUrl}
                alt={object.description}
                key={`certification-${object.description}`}
              />
            ))}
          </ScrollableOverflowExtend>
        </CertificationsRow>
      )}

      <SubSection>
        <ValuePropContainer>
          {valueProps &&
            valueProps.map((prop: ValuePropType) => {
              return (
                <ValuePropWrapper>
                  <ValueProp
                    iconUrl={prop.icon?.file?.url}
                    iconAltText={`value-prop-${prop.name}-icon`}
                  >
                    <span
                      slot="value-prop-name"
                      className="typography-body1 font-circular"
                    >
                      {prop.name}
                      {prop.disclaimer && (
                        <span
                          slot="value-prop-disclaimer"
                          className="font-dutch typography-label3 disclaimer"
                        >
                          {prop.disclaimer}
                        </span>
                      )}
                    </span>
                  </ValueProp>
                </ValuePropWrapper>
              );
            })}
        </ValuePropContainer>

        <FactsPanelWrapper
          nutrientCount={nutrients?.length}
          ingredientCount={ingredients?.length}
        >
          <GridRow>
            <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
              <FactsPanelHeader>
                <FactsPanelTitle className="typography-headline2">
                  {intl.t(
                    "cart.product.hero.supplement-facts.heading",
                    "Supplement Facts",
                  )}
                </FactsPanelTitle>
                <div>
                  <div className="serving-size">
                    <span className="typography-label3">
                      {intl.t(
                        "cart.product.hero.supplement-facts.serving-size",
                        "Serving Size: ",
                      )}
                    </span>
                    <span className="typography-body3">
                      {servingSize.toLowerCase()}
                    </span>
                  </div>
                  <div className="container-servings">
                    <span className="typography-label3">
                      {intl.t(
                        "cart.product.hero.supplement-facts.serving-pr-con",
                        "Servings Per Container: ",
                      )}
                    </span>
                    <span className="typography-body3">
                      {servingsPerContainer}
                    </span>
                  </div>
                </div>
              </FactsPanelHeader>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
              <FactsPanelRowLabels className="header-row">
                <GridRow>
                  <GridColumn xs={{ size: 2 }} s={{ size: 5 }} m={{ size: 5 }} />
                  <GridColumn xs={{ size: 3 }} s={{ size: 5 }} m={{ size: 5 }}>
                    <GridCell>
                      <span className="typography-label3">
                        {intl.t(
                          "cart.product.hero.supplement-facts.amount-per-serve",
                          "Amount Per Serving",
                        )}
                      </span>
                    </GridCell>
                  </GridColumn>
                  <GridColumn xs={{ size: 1 }} s={{ size: 2 }} m={{ size: 2 }}>
                    <GridCell>
                      <span
                        className="typography-label3"
                        dangerouslySetInnerHTML={{
                          __html: labels.dv,
                        }}
                      />
                    </GridCell>
                  </GridColumn>
                </GridRow>
              </FactsPanelRowLabels>

              {!!nutrients &&
                nutrients.map((nutrient: NutrientOrIngredient, index: Number) => {
                  return (
                    <FactsPanelNutrient key={`nutrient-${index}`}>
                      <GridRow>
                        <GridColumn
                          xs={{ size: 3 }}
                          s={{ size: 7 }}
                          m={{ size: 7 }}
                        >
                          <GridCell>
                            <div
                              className={classNames(
                                "cell-label typography-label3",
                                {
                                  "sub-ingredient": nutrient.isSubIngredient,
                                  nutrient: !nutrient.isSubIngredient,
                                },
                              )}
                            >
                              <div
                                className="cell-label-copy"
                                dangerouslySetInnerHTML={{
                                  __html: nutrient.label.replaceAll(
                                    "<span>",
                                    `<span class="${
                                      nutrient.isSubIngredient
                                        ? "typography-body3 font-circular"
                                        : "typography-caption"
                                    }">`,
                                  ),
                                }}
                              />
                            </div>
                          </GridCell>
                        </GridColumn>
                        <GridColumn
                          xs={{ size: 2 }}
                          s={{ size: 3 }}
                          m={{ size: 3 }}
                        >
                          <GridCell>
                            <span
                              className="nutrient-serving typography-label3"
                              dangerouslySetInnerHTML={{
                                __html: nutrient.dosage,
                              }}
                            />
                          </GridCell>
                        </GridColumn>
                        <GridColumn
                          xs={{ size: 1 }}
                          s={{ size: 2 }}
                          m={{ size: 2 }}
                        >
                          <GridCell className="dosage">
                            <span
                              className="ingredient-dv"
                              dangerouslySetInnerHTML={{
                                __html: nutrient.dv,
                              }}
                            />
                          </GridCell>
                        </GridColumn>
                      </GridRow>
                    </FactsPanelNutrient>
                  );
                })}

              {!!ingredients &&
                ingredients.map(
                  (ingredient: NutrientOrIngredient, index: Number) => {
                    return (
                      <FactsPanelIngredient key={`ingredient-${index}`}>
                        <GridRow>
                          <GridColumn
                            xs={{ size: 3 }}
                            s={{ size: 7 }}
                            m={{ size: 7 }}
                          >
                            <GridCell>
                              <div
                                className={classNames("cell-label", {
                                  "sub-ingredient": ingredient.isSubIngredient,
                                  ingredient: !ingredient.isSubIngredient,
                                })}
                              >
                                <div
                                  className={classNames("cell-label-copy", {
                                    "block-display": isHyacera,
                                  })}
                                  dangerouslySetInnerHTML={{
                                    __html: ingredient.label.replaceAll(
                                      "<span>",
                                      `<span class="${
                                        ingredient.isSubIngredient
                                          ? "typography-body3 font-circular"
                                          : "typography-caption"
                                      }">`,
                                    ),
                                  }}
                                />
                              </div>
                            </GridCell>
                          </GridColumn>
                          <GridColumn
                            xs={{ size: 2 }}
                            s={{ size: 3 }}
                            m={{ size: 3 }}
                          >
                            <GridCell>
                              <span
                                className="nutrient-serving"
                                dangerouslySetInnerHTML={{
                                  __html: ingredient.dosage,
                                }}
                              />
                            </GridCell>
                          </GridColumn>
                          <GridColumn
                            xs={{ size: 1 }}
                            s={{ size: 2 }}
                            m={{ size: 2 }}
                          >
                            <GridCell className="dosage">
                              <span
                                className="ingredient-dv"
                                dangerouslySetInnerHTML={{
                                  __html: ingredient.dv,
                                }}
                              />
                            </GridCell>
                          </GridColumn>
                        </GridRow>
                      </FactsPanelIngredient>
                    );
                  },
                )}

              {!!notations && (
                <FactsPanelRow>
                  <GridRow>
                    <GridColumn
                      xs={{ size: 6 }}
                      s={{ size: 12 }}
                      m={{ size: 12 }}
                    >
                      {notations.map((notation: Notation, index: Number) => {
                        return (
                          <GridCell key={`notation-${index}`}>
                            <FactsPanelCaption
                              dangerouslySetInnerHTML={{
                                __html: notation.label,
                              }}
                            />
                          </GridCell>
                        );
                      })}
                    </GridColumn>
                  </GridRow>
                </FactsPanelRow>
              )}
            </GridColumn>
          </GridRow>
        </FactsPanelWrapper>

        <GridRow>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
            <FactsPanelFooter>
              {otherIngredients && (
                <OtherIngredients className="other-ingredients">
                  <FactsPanelBodyLabel>
                    {intl.t(
                      "cart.product.hero.supplement-facts.other-ingredients",
                      "Other Ingredients: ",
                    )}
                  </FactsPanelBodyLabel>
                  <FactsPanelBodyContent
                    dangerouslySetInnerHTML={{
                      __html: otherIngredients,
                    }}
                  />
                </OtherIngredients>
              )}

              {!!showAllergenSection && allergens.length > 0 && (
                <OtherIngredients className="allergens">
                  {allergens.map((allergen: Allergen, index: Number) => {
                      return (
                        <div key={`allergen-${index}`}>
                          <FactsPanelBodyLabel className="typography-label3">{`${allergen.label}: `}</FactsPanelBodyLabel>
                          <FactsPanelBodyContent>
                            {allergen.value}
                          </FactsPanelBodyContent>
                        </div>
                      )
                  })}
                </OtherIngredients>
              )}

              {notationReferences && (
                <div>
                  <FactsPanelCaption
                    className="notation-references"
                    dangerouslySetInnerHTML={{
                      __html: notationReferences,
                    }}
                  />
                </div>
              )}

              {!!attributions && (
                <FactsPanelAttributions>
                  {attributions.map((attribution: Attribution, index: Number) => {
                    return (
                      <FactsPanelCaption
                        className="attributions"
                        key={`attributions-${index}`}
                        dangerouslySetInnerHTML={{
                          __html: attribution.label,
                        }}
                      />
                    );
                  })}
                </FactsPanelAttributions>
              )}
            </FactsPanelFooter>
          </GridColumn>
        </GridRow>
      </SubSection>
    </ContentContainer>
  );
};

export default SupplementFactsPanel;
