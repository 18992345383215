import React, { useEffect, useState } from "react";

type Props = {
  url: string;
};

// Allows us to load an SVG and apply styles to it
const Svg = ({ url }: Props) => {
  const [svgContent, setSvgContent] = useState("");

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        const response = await fetch(url);
        const text = await response.text();
        setSvgContent(text);
      } catch (error) {
        console.error("Error fetching SVG:", error);
      }
    };

    fetchSvg();
  }, [url]);

  return <div dangerouslySetInnerHTML={{ __html: svgContent }} />;
};

export default Svg;
