import styled from "styled-components";
import { useEffect, useState } from "react";
import intl from "../../services/intl";
import { responsive } from "../../utils/style";
import metrics from "../../utils/metrics";

import {
  QuickView,
  QuickViewHeader,
  RCloseButton,
} from "@ritual/essentials-for-react";
import FocusTrap from "focus-trap-react";
import TabGroupPanel from "../bundle/TabGroupPanel";;
import HowToUsePanel, { ImageType } from "./HowToUsePanel";
import SupplementFactsPanel from "./SupplementFactsPanel";

import { SupplementFactsType, ValuePropType } from "../../utils/productTypes";

type Image = ImageType;

type Product = {
  name: {
    name: string
  };
  supplementFacts: SupplementFactsType;
  valueProps: ValuePropType[];
  howToUse: any;
};

type BundleItem = {
  name?: string;
  products: Product[];
  heroImage: Image;
};

export type FlyoutPanelProps = {
  item: BundleItem;
  open: boolean;
  openIndex?: string;
  closeQuickView: () => void;
};

const PanelWrapper = styled.div`
  .quick-view-content-wrapper {
    background-color: var(--warm-20);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: unset;
  }

  &.no-quick-view-footer .quick-view-item-list {
    height: 100%;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.no-overlay overlay-box {
    display: none;
  }

  .quick-view-header {
    padding: var(--spacing-1_5, 24px) var(--spacing-1_5, 24px) 0;
    border-bottom: unset !important;

    .icon-close {
      width: 24px;
      height: 24px;
    }

    .title-wrapper {
      padding-bottom: var(--spacing-1_5, 24px);
      
      div.label {
        margin-bottom: unset !important;
      }
    }

    .caption-wrapper {
      border-bottom: 2px solid var(--indigo-blue);
    }
  }

  .quick-view-footer {
    display: none;
    border-top: unset !important;
  }

  ${responsive.sm`
    .quick-view-item-list {
      overflow-x: hidden;
    }

    .nav-flyout.left {
      min-width: 455px !important;
    }
  `}
`;

const PanelTitle = styled.span.attrs({
  className: "typography-headline2"
})`
  font-weight: 450;
  line-height: 40px;
  letter-spacing: -0.01em;
  font-size: var(--spacing-2, 32px);

  ${responsive.sm`
    font-size: var(--spacing-2_5, 40px);
  `}
`;

const PanelContent = styled.div`
  // border-top: 2px solid var(--indigo-blue);
  margin: 0 var(--spacing-1_5, 24px);
`;

const FlyoutPanel = ({
  item,
  open,
  openIndex,
  closeQuickView,
}: FlyoutPanelProps) => {
  if (!item) return null;

  const { products } = item;
  const productLabels = products?.map((product: Product) => product.name.name);
  const [selectedProduct, setSelectProduct] = useState<any>(products[0]);

  const {
    name: { name: productName },
    howToUse
  } = selectedProduct;

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && open) {
      closeQuickView();
    }
  };

  const selectProduct = (productLabel: string) => {
    const product = products.find(
      (product: Product) => product.name.name === productLabel,
    );
    setSelectProduct(product);

    metrics.track("Flyout Tab Toggled", {
      title: productLabel,
      location: openIndex,
    });
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  const initPageOverflow = typeof document !== "undefined" ? document.body?.style?.overflow : null;
  const [initialOverflow] = useState(initPageOverflow);
  useEffect(() => {
    if (initialOverflow === "hidden") return;

    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open, initialOverflow]);

  const openHowToUse = openIndex === "How to Use";
  const renderPanelContent =
    openHowToUse
      ? <HowToUsePanel
          selectedProduct={selectedProduct}
        />
      : <SupplementFactsPanel
          item={selectedProduct}
        />
  ;

  return (
    <FocusTrap
      active={open}
      focusTrapOptions={{
        allowOutsideClick: true,
        initialFocus: "#product-flyout-panel",
        fallbackFocus: "#product-flyout-panel",
        tabbableOptions: {
          getShadowRoot: true,
        },
      }}
    >
      <PanelWrapper
        id="product-flyout-panel"
        className="no-quick-view-footer"
      >
        <QuickView open={open} closeQuickView={closeQuickView}>
          <QuickViewHeader slot="quick-view-header">
            <PanelTitle slot="title">
              {openHowToUse 
                ? intl.t("product.protein.how-to-use.heading", "How to Use")
                : intl.t("product.supplement-facts.heading", "Supplement Facts")
              }
            </PanelTitle>
            <RCloseButton
              slot="quick-view-header-close"
              handleClose={closeQuickView}
            ></RCloseButton>

            <div slot="quick-view-header-caption">
              <TabGroupPanel selectedLabel={productName} labels={productLabels} selectTabCallback={selectProduct} />
            </div>
          </QuickViewHeader>

          <PanelContent slot="quick-view-items">
            {renderPanelContent}
          </PanelContent>
        </QuickView>
      </PanelWrapper>
    </FocusTrap>
  );
};

export default FlyoutPanel;
