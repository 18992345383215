const bundlePressQuotesDefault = [
  {
    author: "The New York Times",
    icon: "nyt",
    link: "https://www.nytimes.com/2016/05/27/business/vitamins-join-the-clean-label-bandwagon.html",
    quote: {
      quote: "Vitamins Join the 'Clean Label' Bandwagon",
    },
  },
  {
    author: "Wired",
    icon: "wired",
    link: "https://www.wired.com/2016/11/ritual-daily-multivitamins",
    quote: {
      quote:
        "The Controversial Redesign of the Lowly Multivitamin (Yes, Controversial)",
    },
  },
  {
    author: "Vogue",
    icon: "vogue",
    link: "https://www.vogue.com/article/vitamin-delivery-services-customized-transparency-startups",
    quote: {
      quote: "These Tech Startups Want to Disrupt Your Vitamins",
    },
  },
  {
    author: "Forbes",
    icon: "forbes",
    link: "https://www.forbes.com/health/womens-health/best-multivitamins-women/#1377fb125fde",
    quote: {
      quote: "Best Multivitamins For Women In 2024, According To Experts",
    },
  },
];

export default bundlePressQuotesDefault;
