import { AccordionLabel, RButton } from "@ritual/essentials-for-react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import { default as AddBundleButton } from "../components/bundle/AddBundleButton";
import BundleFeaturedArticles from "../components/bundle/BundleFeaturedArticles";
import FAQs from "../components/bundle/FAQs";
import Hero from "../components/bundle/Hero";
import WhatsIncluded from "../components/bundle/WhatsIncluded";
import FollowElement from "../components/FollowElement";
import GridColumn from "../components/grid/GridColumn";
import GridContainer from "../components/grid/GridContainer";
import GridRow from "../components/grid/GridRow";
import Press from "../components/home/Press";
import { MagicSVGImage } from "../components/MagicSVGImage";
import MobileButtonContainer from "../components/MobileButtonContainer";
import ValuePropsTicker from "../components/plp/ValuePropsTicker";
import FlyoutPanel from "../components/product/FlyoutPanel";
import BundleSEO from "../components/seo/Bundle";
import { default as Benefits } from "../components/subscription/Benefits";
import bundlePressQuotesDefault from "../data/product/press";
import { default as intl, default as intlService } from "../services/intl";
import { getStore } from "../store/createStore";
import { productOfferForId } from "../store/product-offer/selectors";
import metrics from "../utils/metrics";
import { getSeoCategoryFromProduct } from "../utils/product";
import { responsive } from "../utils/style";
import { IconMinus, IconPlus } from "../utils/svg";
import { trackProductBundleViewed } from "../utils/tracking/product";

const Background = styled.div`
  background-color: var(--warm-20);
  border-bottom: 1px solid transparent;

  overflow: hidden;
  position: relative;
`;

const MobileHeroImage = styled.div`
  position: relative;
  display: block;
  margin-top: -10%;
  margin-bottom: -15%;
  margin-left: -24px;
  margin-right: -24px;
`;

const Spacer = styled.div`
  margin-top: 58px;
  padding-top: 0;

  ${responsive.md`
    margin-top: 70px;
  `}

  @media (min-width: 750px /* Grid S Tablet */) {
    padding-top: var(--spacing-3);
  }

  &.desktop {
    display: none;

    @media (min-width: 750px /* Grid S Tablet */) {
      display: block;
    }
  }

  &.mobile {
    display: block;

    @media (min-width: 750px /* Grid S Tablet */) {
      display: none;
    }
  }
`;

const ValuePropsSpacer = styled.div`
  margin-bottom: var(--spacing-6);
  border-top: 1px solid var(--indigo-blue-20);
  border-bottom: 1px solid var(--indigo-blue-20);
`;

const ProductMenus = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: var(--spacing-3, 48px);
`;
const AccordionLabelExtend = styled(AccordionLabel)`
  cursor: pointer;
  transitiion: background-color 0.2s ease;
  margin: 0 -24px;
  div.accordion-label {
    margin-left: var(--spacing-1_5, 24px);
  }

  r-button: {
    margin-right: var(--spacing-1_5, 24px);
  }

  .r-button.round-icon button {
    background-color: unset;
  }

  &:hover {
    .r-button.primary button {
      background-color: var(--yellow-ochre-60);
    }
  }

  border-bottom: 1px solid var(--indigo-blue-20);

  &:first-of-type {
    border-top: 1px solid var(--indigo-blue-20);
  }

  ${responsive.sm`
    margin: unset;

    div.accordion-label {
      margin-left: unset;
    }

    r-button: {
      margin-right: unset;
    }
  `}
`;

const Gradient1Keyframes = keyframes`
  0% {
    transform: translate(0%, 0%) scale(1);
  }
  25% {
    transform: translate(-50%, 50%) scale(1.2);
  }
  50% {
    transform: translate(10%, -10%) scale(1.2);
  }
  75% {
    transform: translate(0, 30%) scale(1.2);
  }
  100% {
    transform: translate(0%, 0%) scale(1);
  }
`;

const Gradient2Keyframes = keyframes`
  0% {
    transform: translate(0%, 0%) scale(1)
  }
  25% {
    transform: translate(0%, 25%) scale(1.2)
  }
  50% {
    transform: translate(-10%, 25%) scale(1)
  }
  75% {
    transform: translate(-20%, 25%) scale(1)
  }
  100% {
    transform: translate(0%, 0%) scale(1)
  }
`;

const GradientBase = styled.div`
  width: 500px;
  height: 500px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 230, 102, 1) 20%,
    rgba(255, 230, 102, 0) 100%
  );
  position: absolute;
  pointer-events: none;
  z-index: 0;

  @media (min-width: 933px) {
    width: 800px;
    height: 800px;
  }
`;

const Gradient1 = styled(GradientBase)`
  top: -59px;
  right: -175px;
  animation: ${Gradient1Keyframes} 30s infinite;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }

  @media (min-width: 750px /* Grid S Tablet */) {
    top: 0px;
    right: -80px;
  }

  @media (min-width: 933px) {
    top: -20px;
    right: -140px;
  }
`;

const Gradient2 = styled(GradientBase)`
  top: 45px;
  right: -250px;
  animation: ${Gradient2Keyframes} 20s infinite;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }

  @media (min-width: 750px /* Grid S Tablet */) {
    top: 0px;
    right: -80px;
  }

  @media (min-width: 933px) {
    top: -20px;
    right: -140px;
  }
`;

const GradientFAQ = styled(GradientBase)`
  top: -20px;
  left: -226px;
  z-index: 2;

  @media (min-width: 750px /* Grid S Tablet */) {
    top: -183px;
    left: -298px;
  }

  @media (min-width: 933px) {
    top: -170px;
  }
`;

const FAQWrapper = styled.div`
  position: relative;
`;

const BundlePageTemplate = (props) => {
  const { data } = props;
  const {
    contentful_id,
    slug,
    flag,
    title,
    description,
    heroDescription,
    heroImage,
    ritualProductOffer,
    faq,
    products,
    articles,
  } = data.contentfulProductBundle;

  const productOfferRedux = useSelector((state) =>
    productOfferForId(state, ritualProductOffer?.product_offer_id),
  );

  const price = productOfferRedux.initialAmount(getStore().getState()) / 100;
  const futurePrice =
    productOfferRedux.futureAmount(getStore().getState()) / 100;
  const savings = Math.floor((futurePrice * 100 - price * 100) / 100).toFixed(
    0,
  );
  const currency = intlService.currency.type;

  const hasOOS = !!products.find(
    (product) => product.stockStatus === "out of stock",
  );

  // const [openFlyout, setOpenFlyout] = useState(null);
  const [openFlyout, setOpenFlyout] = useState(false);
  const [flyoutIndex, showFlyoutAtIndex] = useState(null);
  const [bundleItem, setBundleItem] = useState(null);
  const hasTracked = useRef(false);

  useEffect(() => {
    if (!hasTracked.current) {
      trackProductBundleViewed(productOfferRedux);
      hasTracked.current = true;
    }
  }, [productOfferRedux]);

  const handleClick = (index) => {
    setBundleItem(data.contentfulProductBundle);
    setOpenFlyout(true);
    showFlyoutAtIndex(index);

    metrics.track("Flyout Viewed", {
      location: "Bundle PDP",
      name: index,
    });

    return;
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Enter") {
      handleClick(index);
    }
  };

  const onCloseFlyout = () => {
    setOpenFlyout(false);
    setTimeout(() => {
      showFlyoutAtIndex(null);
      setBundleItem(null);
    }, 300);
  };

  const accordionLabels = [
    intl.t("product.supplement-facts.heading", "Supplement Facts"),
    intl.t("product.protein.how-to-use.heading", "How to Use"),
  ];

  const productOfferAttributes = {
    brand: "Ritual",
    availability: hasOOS ? "out of stock" : "in stock",
    condition: "new",
    category: getSeoCategoryFromProduct(),
    currency,
    amount: futurePrice,
    discountAmount: price,
    productOfferId: productOfferRedux.id,
    contentfulId: contentful_id,
    name: productOfferRedux.name,
    description: heroDescription?.heroDescription,
  };

  const seoProps = {
    pagePath: `bundles/${slug}`,
    title: `${productOfferRedux.name} | Ritual`,
    description: heroDescription?.heroDescription,
    image: {
      url: heroImage?.file?.url,
      width: heroImage?.file?.details?.image?.width,
      height: heroImage?.file?.details?.image?.height,
    },
    productAttributes: productOfferAttributes,
  };

  const showFeaturedArticles = articles && articles.length;

  return (
    <Background>
      <BundleSEO {...seoProps} />
      <GridContainer>
        <Spacer className="mobile" />
        <GridRow>
          <GridColumn
            xs={{ size: 6 }}
            s={{ size: 12, hide: true }}
            m={{ size: 12, hide: true }}
          >
            <MobileHeroImage>
              <GatsbyImage
                image={getImage(heroImage?.gatsbyImageData)}
                alt={heroImage?.description}
                style={{
                  width: "100%",
                  aspectRatio: "1 / 1",
                  zIndex: 1,
                }}
              />
            </MobileHeroImage>
          </GridColumn>
          <GridColumn xs={{ size: 6 }} s={{ size: 5 }} m={{ size: 5 }}>
            <Spacer className="desktop" />
            <Hero
              flag={flag}
              title={title}
              description={description}
              heroDescription={heroDescription}
              price={price}
              futurePrice={futurePrice}
              savings={savings}
            >
              <AddBundleButton
                ritualProductOffer={ritualProductOffer}
                oos={hasOOS}
                location={"Bundle PDP Hero"}
                className="fullwidth"
              />
            </Hero>

            <ProductMenus>
              {accordionLabels.map((label, index) => {
                return (
                  <AccordionLabelExtend
                    key={index}
                    onClick={(e) => handleClick(label)}
                    onKeyDown={(e) => handleKeyDown(e, label)}
                  >
                    <span slot="accordion-label">{label}</span>
                    <RButton
                      slot="accordion-icon-button"
                      buttonClass="round-icon primary typography-body3 font-circular"
                    >
                      <MagicSVGImage
                        src={
                          flyoutIndex && flyoutIndex === label
                            ? IconMinus
                            : IconPlus
                        }
                        alt={`${
                          flyoutIndex && flyoutIndex === label
                            ? "Minus"
                            : "Plus"
                        }`}
                        width={24}
                        height={24}
                      />
                    </RButton>
                  </AccordionLabelExtend>
                );
              })}
            </ProductMenus>

            <Benefits products={products} />
            <WhatsIncluded products={products} />
          </GridColumn>
          <GridColumn
            xs={{ size: 6, hide: true }}
            s={{ size: 1 }}
            m={{ size: 1 }}
          />
          <GridColumn
            xs={{ size: 6, hide: true }}
            s={{ size: 6 }}
            m={{ size: 6 }}
          >
            <FollowElement>
              <GatsbyImage
                image={getImage(heroImage?.gatsbyImageData)}
                alt={heroImage?.description}
                style={{
                  width: "100%",
                  aspectRatio: "1 / 1",
                  zIndex: 1,
                }}
              />
            </FollowElement>
          </GridColumn>
        </GridRow>

        <Gradient1 />
        <Gradient2 />
      </GridContainer>
      <ValuePropsSpacer>
        <ValuePropsTicker />
      </ValuePropsSpacer>
      <FlyoutPanel
        openIndex={flyoutIndex}
        open={openFlyout}
        item={bundleItem}
        closeQuickView={onCloseFlyout}
      />
      {faq && (
        <FAQWrapper>
          <FAQs faqs={faq} />
          <GradientFAQ />
        </FAQWrapper>
      )}
      {showFeaturedArticles && (
        <BundleFeaturedArticles articles={articles}></BundleFeaturedArticles>
      )}
      <Press quotes={bundlePressQuotesDefault} background={`var(--warm-20)`} />
      <MobileButtonContainer threshold={670}>
        <AddBundleButton
          ritualProductOffer={ritualProductOffer}
          oos={hasOOS}
          location={"Bundle PDP Hero"}
          price={futurePrice}
          discountPrice={price}
          animated={true}
          className="maxwidth"
        />
      </MobileButtonContainer>
    </Background>
  );
};

export default BundlePageTemplate;

export const query = graphql`
  query ProductBundlePageQuery($locale: String!, $slug: String!) {
    contentfulProductBundle(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      contentful_id
      flag
      title
      slug
      description
      heroDescription {
        heroDescription
      }
      heroImage {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1200
          quality: 90
        )
        description
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      ritualProductOffer {
        product_offer_id
      }
      articles {
        ...featuredArticlesFragment
        heroImage {
          file {
            url
          }
          gatsbyImageData(
            layout: CONSTRAINED
            height: 400
            quality: 90
            cropFocus: LEFT
            resizingBehavior: FILL
          )
        }
      }
      products {
        path
        name {
          name
        }
        sku
        summary
        productSubhead
        sku
        cartImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 240
            quality: 90
          )
          description
        }
        supplementFacts {
          simpleIngredients {
            name
            dosage
          }
          nutrients {
            dv
            dosage
            label
            isSubIngredient
          }
          ingredients {
            dv
            dosage
            label
            isSubIngredient
          }
          otherIngredients
          allergens {
            label
            superset
            value
          }
          attributions {
            label
            superset
          }
          servingsPerContainer
          servingSize
          notations {
            label
          }
          notationReferences
          certifications
          labels {
            dv
          }
        }
        valueProps {
          name
          disclaimer
          icon {
            file {
              url
              fileName
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        howToUse {
          instructions {
            raw
          }
          tooltip {
            content
            type
          }
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: DOMINANT_COLOR
              width: 391
              quality: 90
            )
            description
          }
        }
      }
      faq {
        question
        answer
      }
    }
  }
`;
