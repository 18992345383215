import styled, { keyframes } from "styled-components";
import AsyncRitualButton from "../global/AsyncRitualButton";
import Currency from "../Currency";
import StrikeText from "../global/StrikeText";
import { useEffect } from "react";

type Props = {
  className?: string;
  prefix: string;
  price?: number;
  showPricing?: boolean;
  discountPrice?: number;
  isVisible?: boolean;
};

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const PrefixAnimation = keyframes`
  0% {
    left: 50%;
    transform: translateX(-50%);
  }
  100% {
    left: 0;
    transform: translateX(0);
  }
`;

const PrefixText = styled.span`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  &.animate {
    animation: ${PrefixAnimation} 0.5s 0.5s forwards;
  }
`;

const PricingAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const PricingText = styled.span`
  opacity: 0;

  &.animate {
    animation: ${PricingAnimation} 0.5s 0.5s forwards;
  }
`;

const DiscountButtonAnimated = (props: Props) => {
  const { className, prefix, price, discountPrice, isVisible } = props;

  return (
    <AsyncRitualButton {...props} className={className}>
      <ButtonWrapper>
        <PrefixText className={isVisible ? "animate" : ""}>{prefix}</PrefixText>
        <PricingText className={isVisible ? "animate" : ""}>
          {Currency({ value: discountPrice, round: true })}
          <StrikeText>{Currency({ value: price, round: true })}</StrikeText>
        </PricingText>
      </ButtonWrapper>
    </AsyncRitualButton>
  );
};

export default DiscountButtonAnimated;
