import styled from "styled-components";
import {
  CaptionTitle,
  PriceBreakdown,
  RBadge,
  RPrice,
} from "@ritual/essentials-for-react";
import Currency from "../Currency";

const FlagContainer = styled.div`
  margin-bottom: var(--spacing-0_5);
`;

const HeroContent = styled.div`
  margin-bottom: var(--spacing-3);
  position: relative;
  z-index: 1;
`;

const Title = styled.h1.attrs({ className: "typography-headline2" })`
  margin-bottom: var(--spacing-0_5);
`;

const Subhead = styled.p.attrs({ className: "typography-label1 font-dutch" })`
  font-style: italic;
  color: var(--indigo-blue-60);
  font-weight: 400;

  padding-bottom: var(--spacing-1_5);
  margin-bottom: var(--spacing-1_5);

  border-bottom: 2px solid var(--indigo-blue);
`;

const Pricing = styled.div`
  margin-bottom: var(--spacing-1_5);
`;

type Props = {
  flag?: string;
  title: string;
  description: string;
  heroDescription: {
    heroDescription: string;
  };
  price: number;
  futurePrice?: number;
  savings?: number;
  children: React.ReactNode;
};

const Hero = ({
  flag,
  title,
  description,
  heroDescription,
  price,
  futurePrice,
  savings,
  children,
}: Props) => {
  return (
    <HeroContent>
      {flag && (
        <FlagContainer>
          <RBadge badgeColor="yellow" badgeWidth="wide">
            {flag}
          </RBadge>
        </FlagContainer>
      )}
      <Title>{title}</Title>
      <Subhead>{description}</Subhead>
      <p
        className="typography-body1 font-circular"
        style={{
          marginBottom: "var(--spacing-1_5)",
        }}
      >
        {heroDescription?.heroDescription}
      </p>

      <Pricing>
        <PriceBreakdown priceClass="fullspread">
          <RPrice slot="price-breakdown-final" priceClass="final large">
            {Currency({ value: price, round: true })}
          </RPrice>
          {futurePrice && (
            <RPrice
              slot="price-breakdown-strikethrough"
              priceClass="strikethrough large"
            >
              {Currency({ value: futurePrice, round: true })}
            </RPrice>
          )}
          {savings && (
            <CaptionTitle
              slot="price-breakdown-savings-caption"
              captionClass="typography-body2 font-circular"
            >
              {Currency({ value: savings, round: true })} savings
            </CaptionTitle>
          )}
        </PriceBreakdown>
      </Pricing>

      {children}
    </HeroContent>
  );
};

export default Hero;
