import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import navigationSelectors from "../store/navigation/selectors";

const FollowContainer = styled.div`
  height: 100%; /* Matches the height of its parent */
  position: relative;
`;

const ContentContainer = styled.div`
  position: absolute;
  width: 100%;
`;

type Props = {
  children: React.ReactNode;
  customOffset?: number;
};

const FollowElement = ({ children, customOffset = 0 }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const offset = useSelector(navigationSelectors.offset);

  if (!children) return null;

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const contentHeight = contentRef.current.offsetHeight;
        const scrollY = window.scrollY + 64 + offset + customOffset;
        const containerTop = containerRect.top + window.scrollY;
        const containerBottom = containerRect.bottom + window.scrollY;

        if (
          scrollY >= containerTop &&
          scrollY <= containerBottom - contentHeight
        ) {
          contentRef.current.style.top = `${scrollY - containerTop}px`;
        } else if (scrollY < containerTop) {
          contentRef.current.style.top = "0px";
        } else if (scrollY > containerBottom - contentHeight) {
          contentRef.current.style.top = `${
            containerBottom - contentHeight - containerTop
          }px`;
        } else {
          contentRef.current.style.top = "0px";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call to set position
    return () => window.removeEventListener("scroll", handleScroll);
  }, [offset]);

  return (
    <FollowContainer ref={containerRef}>
      <ContentContainer ref={contentRef}>{children}</ContentContainer>
    </FollowContainer>
  );
};

export default FollowElement;
